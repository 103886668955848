
























// CORE
import { Component, Prop, Vue } from 'vue-property-decorator'

// INTERFACES
import { INameValueItem } from '@/store/types'

// COMPONENTS
import FiltersPanel from '@/components/FiltersPanel.vue'

@Component({
  inheritAttrs: false,
  components: {
    FiltersPanel,
  },
})
export default class DefaultLayout extends Vue {
  @Prop({ default: () => ([]) })
  readonly filters!: INameValueItem[]

  @Prop({ default: () => ([]) })
  readonly breadcrumbs!: INameValueItem[]

  @Prop({ default: 'Основной заголовок страницы' })
  readonly heading!: string

  @Prop({ default: null })
  private modalComponentName!: string

  @Prop({ default: null })
  private drawerComponentName!: string

  @Prop({ default: null })
  private activeStatus!: string

  @Prop({ default: false })
  private isNoBreadcrumbs!: boolean

  @Prop({ default: false })
  private isHideFiltersPanel!: boolean

  private get innerActiveStatus (): string {
    return this.activeStatus
  }

  private set innerActiveStatus (value: string) {
    this.$emit('update:activeStatus', value)
  }
}
