

























// CORE
import { Component, Prop, Vue } from 'vue-property-decorator'

// COMPONENTS
import Card from '@/components/cards/Card.vue'
import { CountResource } from '@/store/types'
import { tableOptionsToFilters } from '@/utils/functions'

@Component({
  inheritAttrs: false,
  components: {
    Card,
  },
})
export default class FiltersPanel extends Vue {
  @Prop({ default: () => ([]) })
  private filters!: CountResource[]

  @Prop({ default: null })
  private modalComponentName!: string

  @Prop({ default: null })
  private drawerComponentName!: string

  @Prop({ default: false })
  private isSmallModal!: boolean

  @Prop({ default: 'all' })
  private value!: string

  @Prop({ default: () => ([]) })
  private formFilters!: []

  @Prop({ default: false })
  private isDisabledButtons!: boolean

  private get innerValue (): string {
    return this.value
  }

  private set innerValue (value: string) {
    this.$emit('input', value)
  }

  private get activeFiltersCount () {
    return Object.keys(tableOptionsToFilters(this.formFilters)).filter(item => item && item!== 'endDate' && !item.includes('EndDate') ? true : false).length
  }
}
