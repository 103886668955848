























































// Core
import { Component, Mixins, Prop, Watch } from 'vue-property-decorator'

// MIXINS
import NotifyMixin from '@/mixins/NotifyMixin'
import SystemMixin from '@/mixins/SystemMixin'

// STORE
import ReportsModule from '@/store/modules/reports'
import FleetReportsModule from '@/store/modules/fleet/reports'
import DriverReportsModule from '@/store/modules/driver/driver-reports'

// TYPES
import { ReportCustomResource } from '@/store/types'

// HELPERS
import { parseDate } from '@/utils/functions'

@Component
export default class ReportCustomCard extends Mixins(NotifyMixin, SystemMixin) {
  @Prop({ default: () => ({}) })
  private report!: ReportCustomResource

  private isFormated = false

  private get isDone (): boolean {
    return !!this.report?.url
  }

  private get getColor (): string {
    switch (this.report?.status?.toLowerCase()) {
    case 'сформировано': return 'green'
    case 'не сформирован' : return 'orange'
    case 'ошибка' : return 'red'
    default: return 'red'
    }
  }

  private async handleOpenDrawer (reportId: number) {
    await ReportsModule.fetchReport(reportId)

    this.$clearDrawerOptions()
    this.$openDrawer('ReportDrawer', { heading: 'Подробности отчета' })
  }

  private handleDownload () {
    if (this.report.url) {
      window.open(this.report.url, '_blank')
    } else {
      ReportsModule.refreshReportFile(this.report.id)
        .then(() => {
          this.$bus.$emit('startReportGenerating', this.report.id)
          this.isFormated = true
          this.notifySuccess('Формирование отчёта запущено.')
          this.fetchReports()
        })
        .catch(() => this.notifyError())
    }
  }

  private handleDelete () {
    ReportsModule.deleteReport(this.report.id)
      .then(() => {
        this.fetchReports()
        this.notifySuccess('Отчет успешно удален.')
      })
      .catch(() => {
        this.notifyError()
      })
  }

  private fetchReports () {
    if (this.role === 'fleet') {
      FleetReportsModule.fetchReports({ carParkId: +this.entity })
    } else {
      DriverReportsModule.fetchReports({ carId: +this.entity })
    }
  }

  private parseDate = parseDate

  @Watch('isDone')
  private isDoneWatcher () {
    if (this.isDone) {
      this.isFormated = false
    }
  }
}
