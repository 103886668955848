


























// Core
import { Component, Mixins } from 'vue-property-decorator'

// MIXINS
import SystemMixin from '@/mixins/SystemMixin'

// Modules
import FleetReportsModule from '@/store/modules/fleet/reports'

import DriverReportsModule from '@/store/modules/driver/driver-reports'
// Components
import DefaultLayout from '@/components/layouts/DefaultLayout.vue'
import ReportDefaultCard from './components/cards/ReportDefaultCard.vue'
import ReportCustomCard from './components/cards/ReportCustomCard.vue'
import ReportEmptyCard from './components/cards/ReportEmptyCard.vue'

@Component({
  components: {
    DefaultLayout,
    ReportDefaultCard,
    ReportCustomCard,
    ReportEmptyCard,
  },
})
export default class ReportsLayout extends Mixins(SystemMixin) {
  private get customReports () {
    if (this.role === 'fleet') {
      return FleetReportsModule.customReports
    } else {
      return DriverReportsModule.customReports
    }
  }

  private get defaultReports () {
    if (this.role === 'fleet') {
      return FleetReportsModule.defaultReports
    } else {
      return DriverReportsModule.defaultReports
    }
  }

  private get customReportsEmpty (): boolean {
    return !!this.customReports.length
  }

  private created () {
    if (this.role === 'fleet') {
      FleetReportsModule.fetchReports({ carParkId: +this.entity })
    } else {
      DriverReportsModule.fetchReports({ carId: +this.entity })
    }
  }
}
