






































// Core
import { Component, Prop, Mixins } from 'vue-property-decorator'

// MIXINS
import NotifyMixin from '@/mixins/NotifyMixin'
import SystemMixin from '@/mixins/SystemMixin'

// STORE
import ReportsModule from '@/store/modules/reports'
import FleetReportsModule from '@/store/modules/fleet/reports'
import DriverReportsModule from '@/store/modules/driver/driver-reports'

// INTERFACES
import { ReportCustomResource } from '@/store/types'

@Component
export default class ReportDefaultCard extends Mixins(NotifyMixin, SystemMixin) {
  @Prop({ default: () => ({}) })
  private report!: ReportCustomResource

  private isFormated = false

  private async handleOpenDrawer (reportId: number) {
    await ReportsModule.fetchReport(reportId)

    this.$clearDrawerOptions()
    this.$openDrawer('ReportDrawer', { isDefault: true, heading: 'Подробности отчета' })
  }

  private handleDownload () {
    if (this.report.url) {
      window.open(this.report.url, '_blank')
    } else {
      ReportsModule.refreshReportFile(this.report.id)
        .then(() => {
          this.$bus.$emit('startReportGenerating', this.report.id)
          this.isFormated = true
          this.notifySuccess('Формирование отчёта запущено.')
          this.fetchReports()
        })
        .catch(() => this.notifyError())
    }
  }

  private async fetchReports () {
    if (this.role === 'fleet') {
      FleetReportsModule.fetchReports({ carParkId: +this.entity })
    } else {
      DriverReportsModule.fetchReports({ carId: +this.entity })
    }
  }
}
